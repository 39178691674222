@import '../../styles/variables';

@keyframes currentWaveRating-barPulse {
    0% { 
      transform: scale(1);
    }
    50% { 
      transform: scale(1.25);
    }
    100% { 
      transform: scale(1);
    }
}

.container {
    display: flex;
    align-items: center;
}

.animate {
    .bar {
        animation: currentWaveRating-barPulse 2s ease-in-out;
    
        // Stagger the animation for each bar
        &:nth-child(1) { animation-delay: 1s; }
        &:nth-child(2) { animation-delay: 1.1s; }
        &:nth-child(3) { animation-delay: 1.3s; }
        &:nth-child(4) { animation-delay: 1.5s; }
        &:nth-child(5) { animation-delay: 1.7s; }
    }
}
.rating {
    margin-right: 8px;
    text-transform: uppercase;
}

.bars {
    display: flex;
}

.bar {
    background-color: $grayLight;
    height: 4px;
    width: 16px;
    margin-right: 2px;
    border-radius: 1px;
}
