@import '../../styles/variables';

.observation {
  align-items: flex-end;
  color: $gray10;
  display: flex;
  margin-bottom: 3px;
  width: 100%;
}

.value {
  line-height: 24px;
}

.unit {
  line-height: 20px;
  text-transform: lowercase;
}

.observationSuffix {
  line-height: 20px;
  margin-left: 6px;
}

.observedBadge {
  margin-bottom: 2px;
  margin-left: 6px;
}

.observedBy {
  align-items: center;
  color: $gray30;
  display: flex;
  width: 100%;
}

.forecasterIcon {
  border-radius: 100%;
  height: 12px;
  overflow: hidden;
  position: relative;
  width: 12px;
}

.observedByValue {
  margin-left: 4px;
}

.separator {
  font-size: 10px;
  line-height: 1em;
  margin: 0 4px;
}

.small {
  .observation {
    margin-bottom: 0;
  }
  .value {
    font-size: 12px;
    line-height: 12px;
  }
  
  .unit {
    font-size: 9px;
    line-height: 10px;
  }
  
  .observationSuffix {
    font-size: 9px;
    line-height: 10px;
  }

  .observedByValue {
    font-size: 9px;
  }
}
